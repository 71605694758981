import * as yup from "yup";
import { DefaultValues } from "./enum";
import { GlassType, WindowType } from "./window";
import { SummaryAgreement } from "./house";
import { t } from "i18next";

export const getRoofElementSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      name: yup.string().typeError(stringValidation).required(requiredValidation).label(t("name")),
      constructionYear: yup
        .number()
        .min(1700)
        .max(new Date().getFullYear())
        .typeError(numberValidation)
        .required(requiredValidation)
        .integer()
        .label(t("constructionYear")),
      slope: yup
        .number()
        .typeError(numberValidation)
        .min(0)
        .max(180)
        .required(requiredValidation)
        .label(t("slope")),
      storey: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .integer()
        .label(t("storey")),
      direction: yup
        .string()
        .matches(/^[a-zA-Z ]*$/, stringValidation)
        .required(requiredValidation)
        .label(t("direction")),
      area: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("surfaceArea")),
      outsideFoil: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("foilOutside")),
      insulationMaterial: yup.string().required(requiredValidation).label(t("insulationMaterial")),
      insulationThickness: yup
        .number()
        .when("insulationMaterial", {
          is: (value: boolean) => {
            value !== undefined ?? false;
          },
          then: (schema) => schema.required(requiredValidation),
        })
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("insulationThickness")),
      visiblePurlinThickness: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("visiblePurlinThickness")),
      kneeBulkhead: yup.boolean(),
      measure: yup.object({
        insulationProduct: yup.object({
          name: yup.string().required(requiredValidation),
        }),
        insulationThickness: yup
          .number()
          .typeError(numberValidation)
          .when("measure.insulationProduct.name", {
            is: (val: string) => val !== undefined,
            then: (schema) => schema.required(requiredValidation),
          })
          .min(0)
          .label(t("insulationThickness")),
        kneeWallHeight: yup
          .number()
          .notRequired()
          .min(0)
          .typeError(numberValidation)
          .when("kneeBulkhead", {
            is: (val: boolean) => val === true,
            then: (schema) => schema.required(requiredValidation),
            otherwise: (schema) => schema.notRequired(),
          })
          .label(t("heightKneeShots")),
        kneeWallHatchCount: yup
          .number()
          .notRequired()
          .min(0)
          .typeError(numberValidation)
          .when("kneeBulkhead", {
            is: (val: boolean) => val === true,
            then: (schema) => schema.required(requiredValidation),
          })
          .label(t("numberOfShutters")),
      }),
      notes: yup.string().typeError(t("validation.string")).label(t("notes")),
      images: yup
        .array()
        .of(
          yup.object({
            filename: yup.string().required(requiredValidation),
            tag: yup.string().required(requiredValidation),
          }),
        )
        .min(1),
    })
    .required();
};
export const getRoofSchema = (t: any) => {
  return yup.object({
    ownerRoof: yup
      .string()
      .typeError(t("validation.string"))
      .required(t("validation.required"))
      .label(t("notes")),
  });
};
export const getSolarSchema = (t: any, numberOfSolarArrays: number) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");
  return yup.object({
    measures: yup.array().notRequired(),
    currentPhaseConnection: yup.string().when("measures", {
      is: (measures: object[]) => !!measures?.length,
      then: (schema) => schema.required(t("validation.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    requiredPhaseConnection: yup.string().when("measures", {
      is: (measures: object[]) => !!measures?.length,
      then: (schema) => schema.required(t("validation.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    mainFuseBoxNeedsReplacement: yup.boolean(),
    mainFuseBoxLocation: yup.string().when("measures", {
      is: (measures: object[]) => !!measures?.length,
      then: (schema) => schema.required(t("validation.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    hasFuseBoxInGarage: yup.boolean(),
    suggestedCableRoute: yup.string().when("measures", {
      is: (measures: object[]) => !!measures?.length,
      then: (schema) => schema.required(t("validation.required")),
      otherwise: (schema) => schema.notRequired(),
    }),
    /**
        * !Leaving this commented out for future use, #341 issue
      requiredPhaseConnection: yup.string().test({
        test: (value, context) => {
          if ((!value || value !== PhaseConnection.THREE_PHASE) && numberOfSolarArrays > 13) {
            return context.createError({ message: "3-fase is vereist" });
          } else if (
            (!value || value !== PhaseConnection.MONO_PHASE) &&
            numberOfSolarArrays <= 13
          ) {
            return context.createError({ message: "1-fase is vereist" });
          }
          return context.resolve(true);
        },
      }),
       */

    ownerSolar: yup
      .string()
      .typeError(stringValidation)
      .when("measures", {
        is: (measures: object[]) => !!measures?.length,
        then: (schema) => schema.required(t("validation.required")),
        otherwise: (schema) => schema.notRequired(),
      }),
    supplierSolar: yup.string().typeError(stringValidation),
    images: yup
      .array()
      .of(
        yup.object({
          filename: yup.string().required(requiredValidation),
          tag: yup.string().required(requiredValidation),
        }),
      )
      .when("measures", {
        is: (measures: object[]) => !!measures?.length,
        then: (schema) => schema.min(1).required(requiredValidation),
        otherwise: (schema) => schema.min(0).notRequired(),
      }),
  });
};
export const getSolarArraySchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      name: yup.string().typeError(stringValidation).required(requiredValidation).label(t("name")),
      panelCount: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("numberOfPanels")),
      panelDirection: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("direction")),
      roofingType: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("roofingType")),
      panelType: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("type")),
      images: yup
        .array()
        .of(
          yup.object({
            filename: yup.string().required(requiredValidation),
            tag: yup.string().required(requiredValidation),
          }),
        )
        .min(1),
    })
    .required();
};
/**
 * Add window business logic:
 * If “new situation” is set to “None (default)” -> all subsequent fields are blocked
 * If “new situation” is set to any non-default value  -> all subsequent fields are unblocked
 * If ventilation grill is set to yes, then ventilation grill width is mandatory
 */

export const getAddWindowFrameSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      name: yup
        .string()
        .matches(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, stringValidation)
        .required(requiredValidation)
        .label(t("name")),
      storey: yup
        .number()
        .min(0)
        .max(10)
        .min(0)
        .required(t("validation.number"))
        .label(t("storey")),
      parentElement: yup.string().required(requiredValidation).label(t("parentElement")),
      frameType: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("frameType")),
      frameCondition: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("frameCondition")),
      windows: yup.array(),
      images: imagesSchema,
    })
    .required();
};
const requiredValidation = t("validation.required");

const imageSchema = yup.object().shape({
  filename: yup.string().required(requiredValidation),
  tag: yup.string().required(requiredValidation),
});

const imagesSchema = yup
  .array()
  .of(imageSchema)
  .test("check-images", requiredValidation, function (value) {
    if (!Array.isArray(value) || value.length === 0) return true;

    return value.every((image) => image.tag.length && image.filename.length);
  });

export const getWallGeneralInfoSchema = (t: any) => {
  return yup.object({
    wallCavityBrushCount: yup
      .number()
      .typeError(t("validation.number"))
      .min(0)
      .required(t("validation.required"))
      .label(t("numOfBrushes")),
    wallDrillDiameter: yup
      .string()
      .typeError(t("validation.string"))
      .required(t("validation.required"))
      .label(t("drillDiameter")),
    ventilationGrilleCount: yup
      .number()
      .typeError(t("validation.number"))
      .required(t("validation.required"))
      .min(0)
      .label(t("ventilationGrilleCount")),
    isScaffoldingRequired: yup.boolean().label(t("aerialWorkPlatform")),
    ownerWall: yup
      .string()
      .typeError(t("validation.string"))
      .required(t("validation.required"))
      .label(t("suggestions")),
    supplierWall: yup.string().typeError(t("validation.string")).label(t("suggestions")),
  });
};

export const getWallElementSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup.object({
    name: yup.string().typeError(stringValidation).required(requiredValidation).label(t("name")),
    constructionYear: yup
      .number()
      .typeError(numberValidation)
      .min(1700)
      .max(new Date().getFullYear())
      .integer()
      .label(t("year")),
    direction: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, stringValidation)
      .required(requiredValidation)
      .label(t("direction")),
    area: yup
      .number()
      .min(1)
      .typeError(numberValidation)
      .required(requiredValidation)
      .label(t("surfaceArea")),
    windLoadedLength: yup
      .number()
      .typeError(numberValidation)
      .min(0)
      .required(requiredValidation)
      .label(t("windLoadedLength")),
    doorCount: yup
      .number()
      .typeError(numberValidation)
      .min(0)
      .required(requiredValidation)
      .label(t("doorCount")),
    wallCondition: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label(t("wallCondition")),
    brickType: yup.string().required(requiredValidation).label(t("brickType")),
    cavityWidth: yup
      .number()
      .min(0)
      .typeError(numberValidation)
      .required(requiredValidation)
      .label(t("cavityWidth")),
    cavityPollution: yup.string().required(requiredValidation).label(t("cavityPollution")),
    insulationMaterial: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label(t("insulationMaterial")),
    insulationThickness: yup
      .number()
      .typeError(numberValidation)
      .when("insulationMaterial", {
        is: (value: boolean) => {
          value !== undefined ?? false;
        },
        then: (schema) => schema.min(0).required(requiredValidation),
      })
      .label(t("insulationThickness")),
    jointCondition: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label("jointCondition"),
    jointType: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label(t("jointType")),
    hasExpansionJoint: yup.boolean().label(t("hasExpansionJoint")),
    hasCrack: yup.boolean().label(t("hasCrack")),
    hasVaporBarier: yup.boolean().label(t("hasVaporBarier")),
    hasVegetation: yup.boolean(),
    hasMoistureProblem: yup.boolean().label(t("hasMoistureProblem")),
    hasHoles: yup.boolean().label(t("hasHoles")),
    measure: yup.object({
      insulationProduct: yup.object({
        name: yup.string().required(requiredValidation),
      }),
      insulationThickness: yup
        .number()
        .typeError(numberValidation)
        .when("insulationProduct.name", {
          is: (val: string) => val !== undefined,
          then: (schema) => schema.min(0).required(requiredValidation),
        })
        .label(t("extraInsulationThickness")),
    }),
    notes: yup
      .string()
      .typeError(t("validation.string"))
      .required(t("validation.required"))
      .label(t("suggestions")),
    images: yup
      .array()
      .of(
        yup.object({
          filename: yup.string().required(requiredValidation),
          tag: yup.string().required(requiredValidation),
        }),
      )
      .min(1),
  });
};

export const getDormerSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      name: yup.string().typeError(stringValidation).required(requiredValidation).label(t("name")),
      dormerType: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("dormerType")),
      width: yup.number().min(0).typeError(numberValidation).label(t("width")),
      length: yup.number().min(0).typeError(numberValidation).label(t("length")),
      height: yup.number().min(0).typeError(numberValidation).label(t("height")),
      insulationCondition: yup
        .string()
        .typeError(stringValidation)
        .required(requiredValidation)
        .label(t("insulationCondition")),
      roofName: yup.string().required(requiredValidation).label(t("name")),
      images: yup
        .array()
        .of(
          yup.object({
            filename: yup.string().required(requiredValidation),
            tag: yup.string().required(requiredValidation),
          }),
        )
        .min(1),
    })
    .required();
};

export const getAtticFloorSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      area: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("surfaceArea")),
      notes: yup.string().typeError(stringValidation).label(t("notes")),
      ridgeHeight: yup
        .number()
        .min(0)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("ridgeHeightAttic")),
      measure: yup.object({
        insulationProduct: yup.object({
          name: yup.string().required(requiredValidation),
        }),
        insulationThickness: yup
          .number()
          .notRequired()
          .typeError(numberValidation)
          .when("measure.insulationProduct.name", {
            is: (val: string) => val !== undefined,
            then: (schema) => schema.required(requiredValidation),
          })
          .min(0)
          .label(t("insulationThickness")),
      }),
      images: yup
        .array()
        .of(
          yup.object({
            filename: yup.string().required(requiredValidation),
            tag: yup.string().required(requiredValidation),
          }),
        )
        .min(1),
    })
    .required();
};

export const getHouseInfoSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");
  const numberValidation = t("validation.number");
  const numberRegex = /^\d+(\.\d+)?(,\d+)?$/;

  return yup
    .object()
    .shape({
      building: yup.object().shape({
        buildingType: yup
          .string()
          .required(requiredValidation)
          .typeError(stringValidation)
          .label(t("buildingType")),
        ridgeHeight: yup
          .number()
          .min(0)
          .typeError(numberValidation)
          .required(requiredValidation)
          .label(t("ridgeHeight")),
        boilerType: yup
          .string()
          .typeError(stringValidation)
          .required(requiredValidation)
          .label(t("boilerType")),
        constructionYear: yup
          .number()
          .min(1700)
          .max(new Date().getFullYear())
          .typeError(numberValidation)
          .required(requiredValidation)
          .label(t("constructionYear")),
        ventilationType: yup
          .string()
          .typeError(stringValidation)
          .required(requiredValidation)
          .label(t("ventilationType")),
        heatingTemperatureClass: yup
          .string()
          .typeError(stringValidation)
          .required(requiredValidation)
          .label(t("heatingTemperatureClass")),
        storeyCount: yup
          .number()
          .min(1)
          .typeError(numberValidation)
          .required(requiredValidation)
          .label(t("numberOfFloors")),
        storeyAreas: yup.array(
          yup.number().min(0).typeError(numberValidation).required(requiredValidation),
        ),
        heatRecoveryInstallationType: yup.string().required(requiredValidation),
      }),
      floorAreas: yup.array().of(
        yup.object({
          area: yup
            .string()
            .matches(numberRegex, numberValidation)
            .transform((value, originalValue) => {
              return typeof originalValue === "string" ? originalValue.replace(",", ".") : value;
            })
            .test("is-number", numberValidation, (value) => !isNaN(parseFloat(value ?? "0")))
            .min(0, numberValidation)
            .typeError(numberValidation)
            .label("surfaceArea"),
          width: yup
            .string()
            .matches(numberRegex, numberValidation)
            .transform((value, originalValue) => {
              return typeof originalValue === "string" ? originalValue.replace(",", ".") : value;
            })
            .test("is-number", numberValidation, (value) => !isNaN(parseFloat(value ?? "0")))
            .min(0, numberValidation)
            .typeError(numberValidation)
            .label("Width"),
          height: yup
            .string()
            .matches(numberRegex, numberValidation)
            .transform((value, originalValue) => {
              return typeof originalValue === "string" ? originalValue.replace(",", ".") : value;
            })
            .test("is-number", numberValidation, function (value) {
              return value === "0" || (!isNaN(parseFloat(value ?? "0")) && isFinite(+(value ?? 0)));
            })
            .min(0, numberValidation)
            .typeError(numberValidation)
            .label("Height"),

          storey: yup.number(),
          storeyName: yup.string(),
        }),
      ),
      accessibility: yup.string().required(t("validation.required")).label(t("accessibility")),
      images: yup.array().of(
        yup.object({
          filename: yup.string().required(requiredValidation),
          tag: yup.string().required(requiredValidation),
        }),
      ),
      hoseDistance: yup.string().required(requiredValidation),
    })
    .required();
};

export const getFloorOverviewSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");
  const numberValidation = t("validation.number");
  return yup.object({
    ownerFloor: yup.string().typeError(stringValidation).required(requiredValidation),
    supplierFloor: yup.string().typeError(stringValidation),
    additionalRenovationVentCount: yup
      .number()
      .typeError(numberValidation)
      .required(requiredValidation)
      .min(0)
      .label(t("additionalRenovationVentCount")),
  });
};

export const getFloorSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  return yup
    .object()
    .shape({
      name: yup.string().typeError(stringValidation).required(requiredValidation).label(t("name")),
      notes: yup.string().typeError(stringValidation).label(t("notes")),
      constructionYear: yup
        .number()
        .min(1700)
        .max(new Date().getFullYear())
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("constructionYear")),
      area: yup
        .number()
        .min(0)
        .notOneOf([0], numberValidation)
        .typeError(numberValidation)
        .required(requiredValidation)
        .label(t("surfaceArea")),
      floorType: yup
        .string()
        .required(requiredValidation)
        .typeError(stringValidation)
        .label(t("floorType")),
      insulationMaterial: yup
        .string()
        .required(requiredValidation)
        .typeError(stringValidation)
        .label(t("insulationMaterial")),
      insulationThickness: yup
        .number()
        .typeError(numberValidation)
        .when("insulationMaterial", {
          is: (val: string) => val !== undefined,
          then: (schema) => schema.required(requiredValidation),
        })
        .label(t("insulationThickness")),
      soilInsulationMaterial: yup
        .string()
        .required(requiredValidation)
        .typeError(stringValidation)
        .label(t("insulationMaterial")),
      soilInsulationThickness: yup
        .number()
        .notRequired()
        .min(0)
        .typeError(numberValidation)
        .when("soilInsulationMaterial", {
          is: (val: string) => val !== undefined,
          then: (schema) => schema.required(requiredValidation),
        })
        .label(t("insulationThickness")),
      crawlspace: yup.string().required(requiredValidation).typeError(stringValidation),
      crawlspaceHeight: yup
        .number()
        .typeError(numberValidation)
        .when("crawlspace", {
          is: (val: string) => val !== undefined,
          then: (schema) => schema.required(requiredValidation),
        })
        .label(t("crawlSpaceHeight")),
      crawlspaceHumidity: yup
        .string()
        .required(requiredValidation)
        .typeError(stringValidation)
        .label(t("crawlspaceHumidity")),
      compartmentCount: yup
        .number()
        .typeError(numberValidation)
        .when("crawlspace", {
          is: (val: string) => val !== undefined,
          then: (schema) => schema.required(requiredValidation),
        })
        .label(t("compartmentCount")),
      measure: yup.object({
        floorInsulationProduct: yup.object({
          name: yup.string().required(t("validation.required")),
        }),
        floorInsulationThickness: yup
          .number()
          .min(0)
          .notRequired()
          .typeError(numberValidation)
          .when("measure.floorInsulationProduct.name", {
            is: (val: string) => val !== undefined,
            then: (schema) => schema.required(requiredValidation),
          })
          .label(t("insulationThickness")),
        soilInsulationProduct: yup.object({
          name: yup.string().required(t("validation.required")),
        }),
        soilInsulationThickness: yup
          .number()
          .min(0)
          .typeError(numberValidation)
          .when("measure.soilInsulationProduct.name", {
            is: (val: string) => val !== undefined,
            then: (schema) => schema.required(requiredValidation),
          })
          .label(t("insulationThickness")),
        soilWallSeparationLength: yup
          .number()
          .min(0)
          .typeError(numberValidation)
          .when("measure.soilInsulationProduct.name", {
            is: (val: string) => val !== undefined,
            then: (schema) => schema.required(requiredValidation),
          })
          .label(t("soilWallSeparationLength")),
      }),
      images: yup
        .array()
        .of(
          yup.object({
            filename: yup.string().required(requiredValidation),
            tag: yup.string().required(requiredValidation),
          }),
        )
        .min(1),
    })
    .required();
};

export const getWindowSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const numberValidation = t("validation.number");
  const stringValidation = t("validation.string");
  const min1Validation = t("validation.number.min1");
  return yup.object({
    name: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .when(
        [
          "glassType",
          "windowType",
          "measure.windowType",
          "measure.glassProduct.name",
          "hasVentilationGrille",
          "measure.ventilationGrilleWidth",
        ],
        {
          is: (
            glassType: GlassType,
            currentWindowType: WindowType,
            newWindowType: WindowType,
            glassProduct: string,
            hasVentilationGrille: boolean,
            ventilationGrilleWidth: number,
          ) =>
            (glassType === GlassType.SINGLE && glassProduct === DefaultValues.NONE) ||
            (glassType === GlassType.SINGLE && glassProduct === undefined) ||
            (glassType === GlassType.DOUBLE && glassProduct === DefaultValues.NONE) ||
            (glassType === GlassType.DOUBLE && glassProduct === undefined) ||
            (glassProduct !== DefaultValues.NONE &&
              hasVentilationGrille !== true &&
              +ventilationGrilleWidth > 0) ||
            (glassProduct !== undefined &&
              hasVentilationGrille !== true &&
              +ventilationGrilleWidth > 0) ||
            (glassProduct !== DefaultValues.NONE &&
              hasVentilationGrille === true &&
              +ventilationGrilleWidth === 0) ||
            (glassProduct !== undefined &&
              hasVentilationGrille === true &&
              +ventilationGrilleWidth === 0) ||
            (glassProduct !== DefaultValues.NONE && currentWindowType !== newWindowType) ||
            (glassProduct !== undefined && currentWindowType !== newWindowType),
          then: (schema) => schema.required(t("validation.required")).min(2),
        },
      )
      .label(t("name")),
    windowType: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label(t("windowType")),
    glassType: yup
      .string()
      .typeError(stringValidation)
      .required(requiredValidation)
      .label(t("glassType")),
    area: yup
      .number()
      .min(0)
      .required(requiredValidation)
      .typeError(numberValidation)
      .label(t("surfaceArea")),
    headThickness: yup
      .number()
      .min(0)
      .max(1000)
      .typeError(numberValidation)
      .required(requiredValidation)
      .label(t("headThickness")),
    hasVentilationGrille: yup.boolean().label(t("hasVentilationGrille")),
    measure: yup.object({
      glassProduct: yup.object({
        name: yup.string().required(t("validation.required")),
      }),
      installVentilationGrille: yup.boolean().notRequired(),
      ventilationGrilleWidth: yup
        .number()
        .notRequired()
        .typeError(t("validation.number"))
        .when("installVentilationGrille", {
          is: (val: boolean) => val,
          then: (schema) => schema.required(t("validation.required")).min(0),
          otherwise: (schema) => schema.notRequired().min(0),
        })
        .label(t("ventilationGrille")),
      windowType: yup.string().typeError(stringValidation).required(requiredValidation),
      isNearFloor: yup.boolean().notRequired(),
    }),

    /**
     *! Glass photo's should be optional #356
     **/

    // images: yup
    //   .array()
    //   .of(
    //     yup.object({
    //       filename: yup.string().required(requiredValidation),
    //       tag: yup.string().required(requiredValidation),
    //     }),
    //   )
    //   .min(1),
  });
};

export const getGlazingSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");
  return yup.object({
    ownerWindow: yup.string().typeError(stringValidation).required(requiredValidation),
    supplierWindow: yup.string().typeError(stringValidation),
  });
};

export const getSummaryPageSchema = (t: any) => {
  const requiredValidation = t("validation.required");
  const stringValidation = t("validation.string");

  return yup.object({
    floor: yup.object({
      preference: yup.string().required(requiredValidation),
      reason: yup
        .string()
        .typeError(stringValidation)
        .when("preference", {
          is: (val: SummaryAgreement) => val === "accept",
          then: () => yup.string().notRequired(),
          otherwise: () => yup.string().required(requiredValidation),
        }),
    }),
    wall: yup.object({
      preference: yup.string().required(requiredValidation),
      reason: yup.string().when("preference", {
        is: "accept",
        then: (schema) => schema.nullable().notRequired(),
        otherwise: (schema) => schema.required(requiredValidation),
      }),
    }),
    roof: yup.object({
      preference: yup.string().required(requiredValidation),
      reason: yup
        .string()
        .typeError(stringValidation)
        .when("preference", {
          is: (val: SummaryAgreement) => val === "accept",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.required(requiredValidation),
        }),
    }),
    solar: yup.object({
      preference: yup.string().required(requiredValidation),
      reason: yup
        .string()
        .typeError(stringValidation)
        .when("preference", {
          is: (val: SummaryAgreement) => val === "accept",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.required(requiredValidation),
        }),
    }),
    windows: yup.array().of(
      yup.object({
        preference: yup.string().required(requiredValidation),
        reason: yup
          .string()
          .typeError(stringValidation)
          .when("preference", {
            is: (val: SummaryAgreement) => val === "accept",
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(requiredValidation),
          }),
      }),
    ),
  });
};
