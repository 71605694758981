import { openDB } from "idb";
import { IInspection, IProduct } from "../../types";

const DB_VERSION = 1;
const INSPECTION_DB = "inspections";
const INSPECTION_STORE = "inspection";
const IMAGES_STORE = "images";
const PRODUCTS_DB = "products";
const PRODUCTS_STORE = "products";
export type Tables =
  | "houseInfo"
  | "wallElement"
  | "windowElement"
  | "roofElement"
  | "dormerElement"
  | "atticElement"
  | "floorElement"
  | "solarElement";

type Images<T extends string> = {
  [x in T]: {
    key: number;
    value: {
      file: Blob | File;
      filename: string;
      tag: string;
      id?: number;
      element?: string;
    };
  };
};

export const openHouseDatabase = async (houseId: number) => {
  const dbName = `houseDB_${houseId}`;
  const db = await openDB(dbName, DB_VERSION, {
    upgrade(database) {
      if (!database.objectStoreNames.contains(PRODUCTS_STORE)) {
        const store = database.createObjectStore(IMAGES_STORE, {
          keyPath: "id",
          autoIncrement: true,
        });
        store.createIndex("id", "id", { unique: true });
      }
    },
  });

  return db;
};

export const addImageToHouseDatabase = async (
  houseId: number,
  imageData: {
    image: string;
    filename: string;
    tag: string;
    id?: number;
    element?: string;
    file?: File;
  },
) => {
  const db = await openHouseDatabase(houseId);

  if (!db) {
    throw new Error(`Failed to open database houseDB_${houseId}`);
  }

  const tx = db.transaction(IMAGES_STORE, "readwrite");
  const store = tx.objectStore(IMAGES_STORE);

  try {
    const imageId = await store.add(imageData);
    console.log(`Image added to IndexedDB with ID: ${imageId}`);
    return imageId;
  } catch (error) {
    console.error("Error adding image to IndexedDB:", error);
    throw new Error("Failed to add image to IndexedDB");
  } finally {
    db.close();
  }
};

export const deleteImageFromHouseDatabase = async (houseId: number, imageId: number) => {
  const db = await openHouseDatabase(houseId);

  if (!db) {
    throw new Error(`Failed to open database houseDB_${houseId}`);
  }
  const tx = db.transaction(IMAGES_STORE, "readwrite");
  const store = tx.objectStore(IMAGES_STORE);
  try {
    await store.delete(imageId);
  } catch (error) {
    console.error("Error getting image data:", error);
    throw new Error("Failed to delete image data from IndexedDB");
  } finally {
    db.close();
  }
};
export const getImageFromHouseDatabase = async (houseId: number, imageId: number) => {
  const db = await openHouseDatabase(houseId);

  if (!db) {
    throw new Error(`Failed to open database houseDB_${houseId}`);
  }

  const tx = db.transaction(IMAGES_STORE, "readonly");
  const store = tx.objectStore(IMAGES_STORE);

  try {
    const imageData = await store.get(imageId);
    return imageData;
  } catch (error) {
    console.error("Error getting image data:", error);
  } finally {
    db.close();
  }
};

export const deleteAllImagesFromHouseDatabase = async (houseId: number) => {
  const db = await openHouseDatabase(houseId);
  if (!db) {
    throw new Error(`Failed to open database houseDB_${houseId}`);
  }

  const tx = db.transaction(IMAGES_STORE, "readwrite");
  const store = tx.objectStore(IMAGES_STORE);

  try {
    await store.clear();
  } catch (error) {
    console.log(`Failed to empty house-${houseId}/images database`);
  } finally {
    db.close();
  }
};
export const db = openDB<Images<Tables>>(IMAGES_STORE, DB_VERSION, {
  upgrade(database) {
    database.createObjectStore("houseInfo", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("wallElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("windowElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("roofElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("dormerElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("atticElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("floorElement", {
      keyPath: "id",
      autoIncrement: true,
    });
    database.createObjectStore("solarElement", {
      keyPath: "id",
      autoIncrement: true,
    });
  },
});

export async function getIDBImage(tableName: Tables, key: Images<Tables>[typeof tableName]["key"]) {
  return (await db).get(tableName, key);
}
export async function setIDBImage(
  tableName: Tables,
  image: Images<Tables>[typeof tableName]["value"],
) {
  return (await db).put(tableName, image);
}
export async function delIDBImage(tableName: Tables, key: Images<Tables>[typeof tableName]["key"]) {
  return (await db).delete(tableName, key);
}
export async function getAllImages(tableName: Tables) {
  return (await db).getAll(tableName);
}

const inspectionDB = openDB<IInspection>(INSPECTION_DB, DB_VERSION, {
  upgrade(database) {
    if (!database.objectStoreNames.contains(INSPECTION_STORE)) {
      const store = database.createObjectStore(INSPECTION_STORE, {
        keyPath: "sphId",
        autoIncrement: true,
      });
      store.createIndex("sphId", "sphId", { unique: true });
    }
  },
});

export const updateIndexedDB = async (inspection: IInspection) => {
  return (await inspectionDB).put(INSPECTION_STORE, inspection);
};

export const getDBInspection = async (sphId: number) => {
  return (await inspectionDB).get(INSPECTION_STORE, sphId);
};

const productsDB = openDB<IProduct>(PRODUCTS_DB, DB_VERSION, {
  upgrade(database) {
    if (!database.objectStoreNames.contains(PRODUCTS_STORE)) {
      const store = database.createObjectStore(PRODUCTS_STORE, {
        keyPath: "id",
        autoIncrement: true,
      });
      store.createIndex("id", "id", { unique: true });
    }
  },
});

export const getProductsIndexDB = async () => {
  return (await productsDB).getAll(PRODUCTS_STORE);
};

export const updateProductsIndexDB = async (products: IProduct[]) => {
  (await productsDB).clear(PRODUCTS_STORE); // new products are going to be set to DB every time. There is no need to save multiple products for different inspections
  return (await productsDB).put(PRODUCTS_STORE, products);
};

export const clearDB = async () => {
  (await inspectionDB).clear(INSPECTION_STORE);
  (await productsDB).clear(PRODUCTS_STORE);
  (await db).clear("atticElement");
  (await db).clear("dormerElement");
  (await db).clear("floorElement");
  (await db).clear("houseInfo");
  (await db).clear("roofElement");
  (await db).clear("solarElement");
  (await db).clear("solarElement");
  (await db).clear("wallElement");
  (await db).clear("windowElement");
};
