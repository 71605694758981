import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Box, CardMedia, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Compressor from "compressorjs";
import { FC, useCallback, useEffect, useState } from "react";
import deleteIcon from "../../assets/icons/delete.svg";
import { useAppDispatch } from "../../store/hooks";
import { Card } from "../common/Container/Containers";
import { PhotoModal } from "./Modal";
import { WarningIcon } from "../common/Icons";
import {
  AddPhotoEmptyContainer,
  ImageOverlay,
  PhotoBoxStyled,
  PhotosContainer,
} from "./Photos.styled";
import { deleteImage, fetchImage, uploadInspectionImage } from "../../store/thunks";
import { FreeFormSelect } from "../common/Form/FormSelect";
import { IImage } from "../../types/image";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useConfirm } from "../common/Dialog/ConfirmDialog";
import { useNetworkStatus } from "../../utils/hooks/networkStatus";
import {
  addImageToHouseDatabase,
  deleteImageFromHouseDatabase,
  getImageFromHouseDatabase,
} from "src/store/indexedDB/objectStore";
import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";
import { Prefixes } from "src/types";
import customLocalStorage from "src/utils/localStorage";
import AddPhotoButton from "./AddPhotoButton/AddPhotoButton";

export const blobToBase64 = (blob: File | Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertBlobToBase64 = async (blob: File | Blob) => {
  return await blobToBase64(blob);
};

type PhotosProps = {
  tags: any;
  sphId: string;
  form: any;
  isWindowsPage?: boolean;
  housePartElement?: string;
  elementName?: string;
  parentName?: string;
  parentElement?: string;
};

export type ImageType = {
  image: string;
  filename: string;
  tag: string;
  id?: number;
};

export const Photos: FC<PhotosProps> = ({
  tags,
  sphId,
  form,
  isWindowsPage,
  housePartElement,
  elementName,
  parentName,
  parentElement,
}) => {
  const isOnline = useNetworkStatus();
  const { t } = useTranslation();
  const [modalImg, setModalImg] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => setIsOpen(!isOpen);
  const dispatch = useAppDispatch();
  const [imageList, setImageList] = useState(form.getValues("images"));
  const confirm = useConfirm();
  const [images, setImages] = useState<ImageType[]>([]);
  const formImages = form.watch("images");

  useEffect(() => {
    setImageList(form.getValues("images"));
  }, [form, formImages]);

  const handleImage = async (imageArr: HTMLInputElement["files"]) => {
    /**
     * 1. Resize image
     * 2. Upload image to BE ( Show dynamic upload progress or just some animation? )
     * 3. Take reference path from response and store it together with tag in corresponding store slice
     *
     *  */
    if (!imageArr) return;

    for (let i = 0; i < imageArr?.length; i++) {
      const element = imageArr[i];

      /**Offline mode - try to upload .jfif image, request will be stored in localstorage, once
       * connection is restored, that request will fail as they only accept `jpeg` or `jpg` images
       */
      const imageExtension = element.name.substring(element.name.lastIndexOf(".") + 1);

      if (imageExtension !== "jpeg" && imageExtension !== "jpg") {
        toast.error(t("failedUpload"));
        continue;
      }

      new Compressor(element, {
        quality: 0.6,
        maxHeight: Infinity,
        maxWidth: Infinity,
        resize: "none",

        async success(file) {
          const b64 = (await convertBlobToBase64(file)) as string;

          if (file instanceof File) {
            dispatch(uploadInspectionImage({ sphId, image: file, filename: element.name }))
              .unwrap()
              .then(async (res: any) => {
                if (res.response.status !== 200) {
                  toast.error(t("failedUpload"));
                  return;
                }
                const newImage = { filename: res.filename, tag: "" };
                const formImages = form.getValues("images");
                const newImages = [...formImages, newImage];
                form.setValue("images", newImages);

                setImages((prevImages) => [
                  ...prevImages,
                  { filename: res.filename, image: b64, tag: "" },
                ]);
                form.setValue("images");
                toast.success(t("successfulUpload"));
              })
              .catch((rejectedValueOrSerializedError) => {
                toast.error(t("failedUpload"));
              });
          } else {
            const compressedFileFromBlob = new File([file], element.name, {
              type: file.type,
            });

            dispatch(
              uploadInspectionImage({
                sphId,
                image: compressedFileFromBlob,
                filename: element.name,
              }),
            )
              .unwrap()
              .then(async (res) => {
                if (!res) {
                  toast.error(t("failedUpload"));
                  return;
                }
                const newImage = { filename: res.imgName, tag: "" };
                const formImages = form.getValues("images");
                const newImages = [...formImages, newImage];
                form.setValue("images", newImages);

                setImages((prevImages) => [
                  ...prevImages,
                  { filename: res.imgName, image: b64, tag: "" },
                ]);
                toast.success(t("successfulUpload"));
              })
              .catch(async (error) => {
                if (error.code === "ERR_NETWORK") {
                  toast.error(t("networkFailure"));
                } else {
                  toast.error(t("rejectImageUpload"));
                }

                /** Store not uploaded image in indexedDB */
                const imageID = await addImageToHouseDatabase(+sphId, {
                  filename: Prefixes.LOCAL + element.name,
                  tag: "",
                  image: b64,
                  file: compressedFileFromBlob,
                });

                /** Extend images state with the new locally stored image */
                const newImage = {
                  id: +imageID,
                  filename: Prefixes.LOCAL + element.name,
                  tag: "",
                };

                setImages((prevImages) => [...prevImages, { ...newImage, image: b64 }]);
                form.setValue("images", [...form.getValues("images"), newImage]);

                /** Extend local storage with information about failed image requests, this data will be used on Summary page,
                 *  when internet connection is restored for sending image requests */
                const errorRequest = {
                  filename: Prefixes.LOCAL + element.name,
                  housePartElement: housePartElement ?? "",
                  elementName: elementName ?? "",
                  id: +imageID,
                  parentName: parentName ?? "",
                  parentElement: parentElement ?? "",
                };

                const parsedRequests = customLocalStorage.getItem(
                  `${Prefixes.HOUSE_IMAGES}${sphId}`,
                );

                customLocalStorage.setItem(
                  `${Prefixes.HOUSE_IMAGES}${sphId}`,
                  JSON.stringify([...parsedRequests, errorRequest]),
                );
              });
          }
        },
      });
    }
  };

  const getImages = useCallback(async () => {
    imageList?.forEach(async (element: IImage) => {
      if (!element.filename.includes(Prefixes.LOCAL)) {
        const data = await fetchImage(+sphId, element);
        const blobToImg: string = data ? ((await convertBlobToBase64(data.file)) as string) : "";
        if (data) {
          setImages((prevImages) => [
            ...prevImages,
            { filename: data.filename, tag: data.tag, image: blobToImg },
          ]);
        }
      } else {
        // localy stored images
        const res = await getImageFromHouseDatabase(+sphId, element.id ? element.id : -1);
        if (res) setImages((prevImages) => [...prevImages, { ...element, image: res.image }]);
      }
    });
  }, []);

  useEffect(() => {
    getImages();
  }, []);

  const onDeleteImage = async (
    e: React.MouseEvent<HTMLImageElement>,
    imageName: string,
    imageId: number,
  ) => {
    e.stopPropagation();
    const choice = await confirm({
      title: t("delete"),
      description: t("deletePicture"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      const filteredImages = imageList.filter((image: IImage) => image.filename !== imageName);
      setImages(images.filter((image) => image.filename !== imageName));
      form.setValue("images", filteredImages);
      await deleteImage(+sphId, imageName);

      if (imageId !== -1) {
        // Delete not unploaded image from indexedDB
        await deleteImageFromHouseDatabase(+sphId, imageId);

        // Update images state
        const filteredImages = images.filter((img) => img.id !== imageId);
        setImages(filteredImages);

        // Remove the request from local storage associated with the deleted image
        const requests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${sphId}`);
        const updatedRequests = requests.filter(
          (item: FailedImageUploadRequest) => item.id !== imageId,
        );

        // Update the local storage
        updatedRequests.length
          ? customLocalStorage.setItem(
              `${Prefixes.HOUSE_IMAGES}${sphId}`,
              JSON.stringify(updatedRequests),
            )
          : customLocalStorage.removeItem(`${Prefixes.HOUSE_IMAGES}${sphId}`);
      }

      toast.success(t("success"));
    }
  };

  const handleTag = async (tag: string, filename: string) => {
    const mappedImages = imageList.map((image: ImageType) => {
      if (image.filename === filename) return { ...image, tag };

      return image;
    });

    form.setValue("images", mappedImages);
  };

  return (
    <Grid container columns={12}>
      <Card
        width="100%"
        sx={{
          border: images.length
            ? "transparent"
            : isWindowsPage
            ? "transparent"
            : "2px solid #A21313",
        }}
      >
        <Typography variant="h3">
          {t("pictures")} {images.length > 0 && <>({images.length})</>}
        </Typography>
        <Grid item mt={2}>
          {images.length ? (
            <PhotosContainer>
              {images.map((image, index: number) => {
                const isImageLocalyStored = image.filename.startsWith(Prefixes.LOCAL);

                return (
                  <PhotoBoxStyled p={2} key={`${index}-${image.filename}`}>
                    <CardMedia
                      component="img"
                      image={image.image as unknown as string}
                      loading="eager"
                      alt={image.filename as string}
                    />
                    <ImageOverlay
                      onClick={(element) => {
                        const prevSibling = element.currentTarget
                          .previousSibling as HTMLImageElement;
                        const src = prevSibling?.src;
                        if (src) {
                          setModalImg(src);
                          handleModal();
                        }
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={(event) => onDeleteImage(event, image.filename, image.id ?? -1)}
                        id={`${index}`}
                      />
                      {isImageLocalyStored ? (
                        <WarningIcon position="absolute" left="40%" top="30%" />
                      ) : null}
                    </ImageOverlay>
                    <Box bgcolor="#405360" p={1} height="100%">
                      <FreeFormSelect
                        options={tags}
                        label={t("selectTag")}
                        key={`${image.filename}_${index}`}
                        defaultValue={image.tag || ""}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-root": { background: "white" },
                          ".MuiInputLabel-shrink": { top: "8px" },
                        }}
                        onChange={(event) => handleTag(event.target.value, image.filename)}
                      />
                    </Box>
                  </PhotoBoxStyled>
                );
              })}
              <AddPhotoButton handleImage={handleImage} />
            </PhotosContainer>
          ) : (
            <AddPhotoEmptyContainer>
              <AddPhotoButton handleImage={handleImage} />
              {images.length > 0 && <p>{t("imageTagError")}</p>}
            </AddPhotoEmptyContainer>
          )}
        </Grid>
      </Card>
      <PhotoModal imageSrc={modalImg} isOpen={isOpen} handlePhotoModal={handleModal} />
    </Grid>
  );
};
