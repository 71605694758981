import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, FormControlLabel, Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import HouseIcon from "../../assets/icons/home_icon.svg";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card, CardSection } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";

import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import {
  EmptyContainer,
  SectionTitle,
} from "../../components/common/StepComponents/StepContainers";
import { DividerStack } from "../../components/common/StepComponents/StepContainers.styled";
import { Path, useNavigation } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import {
  duplicateElement,
  getFilteredSuggestions,
  getInspectionData,
  getWallGeneralInfo,
  getWalls,
  saveAdditionalInspectionData,
} from "../../store/slices";
import { IWall, SuggestionCategory, WallDrillDiameter } from "../../types";
import { getWallGeneralInfoSchema } from "../../types/schema";
import { PlusIcon } from "../../components/common/Icons";
import { StyledCheckbox } from "../../components/common/StepComponents/Checkbox.styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import stringToNumber from "../../utils/helpers/strToNum";
import type { InferType } from "yup";

type WallPartsProps = {
  element: Pick<IWall, "name" | "errors" | "area" | "direction" | "windowGroups">;
  handleClick: () => void;
  handleDuplicate: () => void;
};

const WallParts: FC<WallPartsProps> = ({ element, handleClick, handleDuplicate }) => {
  return (
    <CardSection
      title={element.name}
      errors={element.errors || []}
      area={element.area}
      direction={element.direction}
      icon={HouseIcon}
      path={"/"}
      handleClick={handleClick}
      handleDuplicate={handleDuplicate}
    />
  );
};

export const Wall: FC = () => {
  const wallGeneralInfo = useAppSelector(getWallGeneralInfo);
  const walls = useAppSelector(getWalls);
  const inspectionData = useAppSelector(getInspectionData);

  const dispatch = useDispatch();
  const navigate = useNavigation();
  const { t } = useTranslation();
  const wallDrillDiameterOptions = Object.values(WallDrillDiameter);

  const wallScheema = getWallGeneralInfoSchema(t);
  type WallType = InferType<typeof wallScheema>;

  const form = useForm<WallType>({
    resolver: yupResolver(wallScheema),
    mode: "all",
    defaultValues: {
      wallCavityBrushCount: wallGeneralInfo?.wallCavityBrushCount,
      wallDrillDiameter: wallGeneralInfo?.wallDrillDiameter,
      isScaffoldingRequired: wallGeneralInfo?.isScaffoldingRequired || false,
      ownerWall: wallGeneralInfo?.ownerWall || "",
      supplierWall: wallGeneralInfo?.supplierWall || "",
      ventilationGrilleCount: inspectionData?.ventilationGrilleCount || 0,
    },
  });
  const {
    register,
    reset,
    control,
    trigger,
    formState: { errors },
  } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    reset({
      wallCavityBrushCount: wallGeneralInfo?.wallCavityBrushCount,
      wallDrillDiameter: wallGeneralInfo?.wallDrillDiameter,
      isScaffoldingRequired: wallGeneralInfo?.isScaffoldingRequired || false,
      ownerWall: wallGeneralInfo?.ownerWall || "",
      supplierWall: wallGeneralInfo?.supplierWall || "",
      ventilationGrilleCount: inspectionData?.ventilationGrilleCount || 0,
    });
  }, [reset, wallGeneralInfo]);

  const handleSave = async () => {
    await form.trigger();
    if ("ownerWall" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ownerWall");
      return;
    }

    if ("ventilationGrilleCount" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ventilationGrilleCount");
      return;
    }

    dispatch(saveAdditionalInspectionData({ ...form.getValues() }));
    navigate(`../${Path.Flat}`);
  };
  const wallSuggestionsOwner = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.OWNER_WALL),
  );
  const wallSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_WALL),
  );

  const handleDuplicate = (wallName: string) =>
    dispatch(duplicateElement({ name: wallName, housePart: "wall" }));
  return (
    <MainPageWrapper>
      <Cover title={t("walls")} />
      <PageWrapper>
        <Stack sx={{ mt: 3 }}>
          <SectionTitle title={t("walls")}>
            <MainButton
              text={t("add")}
              icon={<PlusIcon color="white" width="10px" />}
              variant="secondary"
              onClick={() => navigate(Path.AddWallElement)}
            />
          </SectionTitle>
          {walls.length ? (
            <Card sx={{ px: 0 }}>
              <DividerStack divider={<Divider />} spacing={2}>
                {walls.map((wall) => (
                  <WallParts
                    key={wall.name}
                    element={wall}
                    handleClick={() => navigate(Path.AddWallElement, { wallId: wall?.name })}
                    handleDuplicate={() => handleDuplicate(wall?.name)}
                  />
                ))}
              </DividerStack>
            </Card>
          ) : (
            <EmptyContainer title={`${t("noWallsAdded")}`} />
          )}

          <Box>
            <Form onSubmit={form.handleSubmit(handleSave, handleSave)}>
              <SectionTitle title={t("additionalInspectionData")} sx={{ mt: 5 }}></SectionTitle>
              <Card>
                <SectionTitle title={t("general")} sx={{ mb: 2 }}></SectionTitle>
                <FormStack>
                  <FormInput
                    label={t("numOfBrushes")}
                    {...register("wallCavityBrushCount", { setValueAs: (v) => stringToNumber(v) })}
                    error={errors.wallCavityBrushCount}
                  />
                  <FormSelect
                    name="wallDrillDiameter"
                    form={form}
                    label={t("drillDiameter")}
                    error={errors.wallDrillDiameter}
                    options={wallDrillDiameterOptions}
                  />

                  <FormInput
                    label={t("ventilationGrilleCount")}
                    {...register("ventilationGrilleCount", {
                      setValueAs: (v) => stringToNumber(v),
                    })}
                    error={errors.ventilationGrilleCount}
                  />
                  <Controller
                    control={control}
                    name={"isScaffoldingRequired"}
                    defaultValue={form.getValues("isScaffoldingRequired")}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        label={t("isScaffoldingRequired")}
                        control={<StyledCheckbox checked={value || false} onChange={onChange} />}
                      />
                    )}
                  />
                </FormStack>
              </Card>
              <AdditionalDataWrapper title={t("ownerAgreements")}>
                <AdditionalData
                  name="ownerWall"
                  form={form}
                  suggestions={wallSuggestionsOwner}
                  error={errors.ownerWall}
                />
              </AdditionalDataWrapper>
              <AdditionalDataWrapper title={t("contractorAgreements")}>
                <AdditionalData
                  name="supplierWall"
                  form={form}
                  suggestions={wallSuggestionExecutive}
                  error={errors.supplierWall}
                />
              </AdditionalDataWrapper>
              <MainButton
                text={t("save")}
                variant="primary"
                type="submit"
                sx={{ margin: "30px 0" }}
              />
            </Form>
          </Box>
        </Stack>
      </PageWrapper>
    </MainPageWrapper>
  );
};
