import HistoryIcon from "@mui/icons-material/History";
import { CardContent, Grid, Modal, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { MainButton } from "../../components/common/Button/MainButton";
import { BlueBgPageWrapper } from "../../components/common/Container/BlueWrapper";
import { Card } from "../../components/common/Container/Containers";
import { CardSection } from "../../components/common/StepComponents/StepContainers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { Cover } from "../../components/common/Cover/Cover";
import { HouseInspectionCard } from "./HouseInspectionCard";
import { InspectionCardHeader } from "./Houses.styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchInspection, fetchHouses, fetchProducts } from "../../store/thunks";
import { Path } from "../../routes";
import {
  getHousesLoadingStatus,
  initEmptyInspection,
  syncStateWithIndexDB,
} from "../../store/slices";
import customLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DefaultValues, IInspection, Loading } from "../../types";
import { useFilteredHouse } from "../../utils/hooks/useFilteredHouse";
import { getDBInspection } from "../../store/indexedDB/objectStore";
import { toast } from "react-toastify";
import { HouseIcon } from "../../components/common/Icons";
import { useNetworkStatus } from "src/utils/hooks/networkStatus";
import { useConfirm } from "src/components/common/Dialog/ConfirmDialog";

const InspectionHistoryCardContainer = styled(Card)(() => ({
  ">div:nth-of-type(n):not(:last-child)": {
    borderBottom: "2px solid #DDD",
  },
}));

export const HouseInspection: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const filteredHouse = useFilteredHouse();
  const sphId = Number(id);
  const housesLoadingStatus = useAppSelector(getHousesLoadingStatus);
  const dispatch = useAppDispatch();
  const isOnline = useNetworkStatus();
  const navigate = useNavigate();
  const [localInspection, setLocalInspection] = useState<IInspection>();
  const confirm = useConfirm();

  useEffect(() => {
    if (isOnline) {
      (async () => await dispatch(fetchProducts(sphId)))();
    }
  }, [isOnline, sphId, dispatch]);

  const handleContinueLocalInspection = async () => {
    dispatch(syncStateWithIndexDB(localInspection));
    toast.info(t("previousInspectionStarted"));
    customLocalStorage.setSphId(sphId.toString());
    navigate(Path.HouseInfo);
  };

  const handleStartNewInspection = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (localInspection) {
      const choice = await confirm({
        title: t("delete"),
        description: t("overwriteOflineDocument"),
        confirmBtnLabel: t("yes"),
      });
      if (!choice) return;
    }
    dispatch(initEmptyInspection(sphId));
    toast.info(t("newInspectionStarted"));
    customLocalStorage.setSphId(sphId.toString());
    navigate(Path.HouseInfo);
  };

  const handleEditInspection = async () => {
    const response = await dispatch(fetchInspection(sphId));
    if (response.type === "fetchInspection/rejected")
      return toast.error(
        `${t("failedToFetchInspection")} ${response.payload?.length ? response.payload : ""}`,
      );

    toast.info(t("existingServerInspectionStarted"));
    customLocalStorage.setSphId(sphId.toString());

    navigate(Path.HouseInfo);
  };

  useEffect(() => {
    if (housesLoadingStatus === Loading.IDLE) dispatch(fetchHouses());
  }, [dispatch, housesLoadingStatus]);

  const fullAddressName = filteredHouse?.streetName + " " + filteredHouse?.houseNumber;
  const title = String(fullAddressName);

  useEffect(() => {
    const existingInspection = async () => {
      const data = await getDBInspection(sphId);
      if (data) setLocalInspection(data);
    };
    existingInspection();
  }, [sphId]);

  const formatDateToNL = (date?: string) => {
    if (date) return new Date(date).toLocaleString("nl-NL");

    return DefaultValues.UNKNOWN;
  };

  return (
    <MainPageWrapper>
      <Cover title={title} showBackButton={true} previousPage={true} />
      <BlueBgPageWrapper px={6}>
        <Grid container pb={5}>
          <HouseInspectionCard />
          <Grid item xs={12} mb={12}>
            <InspectionHistoryCardContainer p={0}>
              <InspectionCardHeader
                avatar={<HistoryIcon sx={{ fontSize: 30 }} />}
                title={t("inspectionHistory")}
              />
              {localInspection && (
                <CardContent sx={{ padding: "24px" }}>
                  <CardSection
                    title={t("inspectionReport")}
                    Icon={HouseIcon}
                    hasInspection={filteredHouse?.hasInspection}
                    localInspection={!!localInspection}
                    handleClick={handleContinueLocalInspection}
                    inspectorName={filteredHouse?.inspectionSenderName ?? ""}
                    inspectionReceivedTime={formatDateToNL(localInspection?.updateTime)}
                  />
                </CardContent>
              )}
              <CardContent sx={{ padding: "24px" }}>
                <CardSection
                  title={t("inspectionReport")}
                  Icon={HouseIcon}
                  hasInspection={filteredHouse?.hasInspection}
                  handleClick={handleEditInspection}
                  inspectorName={filteredHouse?.inspectionSenderName ?? DefaultValues.UNKNOWN}
                  inspectionReceivedTime={formatDateToNL(filteredHouse?.inspectionReceivedTime)}
                />
              </CardContent>
            </InspectionHistoryCardContainer>
          </Grid>
          <MainButton
            handleClick={handleStartNewInspection}
            text={t("startInspection")}
            variant="primary"
          />
        </Grid>
      </BlueBgPageWrapper>
    </MainPageWrapper>
  );
};
